import { proxy, useSnapshot } from 'valtio'
import { useProxy } from 'valtio/utils'

import { getCookie } from '@/utils/cookie'

interface user {
  avatarUrl?: string
  balance?: number
  memberExpireTime?: string
  memberStatus?: number
  mobileNo?: string
  nickname?: string
  userId?: number
  isBindWx?: boolean
  specialInfo?: object
  auths?:string[]
}

interface stateInterface {
  userInfo: user
  isLogin: boolean
  isRecord: boolean
  showRewardPop: boolean
}

const defaultState: stateInterface = {
  userInfo: {},
  isLogin: getCookie('token') ? true : false,
  isRecord: false,
  showRewardPop: false,
}

export const baseState = proxy({
  ...defaultState,
})

export const useStore = () => {
  return useProxy(baseState)
}
export const setUserInfo = (data?: any) => {
  baseState.userInfo = data
  window.localStorage.setItem('userInfo', JSON.stringify(data))
}

// useStore() 报错的话，用 localStorage
export const getUserInfo = () => {
  try {
    const { userInfo } = useStore()
    return userInfo
  } catch (err) {
    try {
      const userInfoStr = window.localStorage.getItem('userInfo')
      const userInfo = userInfoStr ? JSON.parse(userInfoStr) : {}
      return userInfo
    } catch (err) {
      return defaultState.userInfo
    }
  }
}

export const setShowRewardPop = (data?: any) => {
  baseState.showRewardPop = data
}

export const setIsLogin = (data?: any) => {
  baseState.isLogin = data
}
export const setIsRecord = (data?: any) => {
  baseState.isRecord = data
}

export const useBasePlatform = () => {
  const baseSnap = useSnapshot(baseState)

  return {
    baseSnap,
    baseState: baseState,
  }
}
