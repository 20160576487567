import React, { ReactNode, useEffect, useState } from 'react'
import styles from './index.less'
import { useStore } from '@/states/store'
import ReactDOM from 'react-dom/client'
import openPayDialog from '../PayDialog'
import { MemberType } from '@/constants'
import { history } from 'umi'
import RprmUtils from '@/utils/dot'

// 首页的海报创作相似，需要判断是否登录、是否会员、是否9.9、是否拦截等等，判断条件结束后cb()
const Intercept = ({ pathName, onCancel, cb }: any) => {
  const { userInfo } = useStore()

  const getCurrentType = () => {
    if (!pathName) return 'productMainImage'

    if (/create\/marketing-poster/.test(pathName)) {
      return 'marketingPoster'
    }
    if (/create\/product-details/.test(pathName)) {
      return 'productDetails'
    }
  }

  useEffect(() => {
    RprmUtils.rec(`show_intercept_dialog`, 'view', { en: 'tap', et: 'custom' })
  }, [])

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={`${styles.closeBtn} payInterceptClose`} onClick={() => onCancel()}>
          <span className="icon-Close payInterceptClose"></span>
        </div>
        <img
          src={
            getCurrentType() === 'marketingPoster'
              ? 'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/qingsongzhizuoAIshangpinhaibao.png'
              : 'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/shangchuanshangpintuyijianshengchengfengfushangxiangye1.png'
          }
          alt=""
        />
        <img
          className={styles.paySecondIntercept}
          src={
            userInfo?.specialInfo?.isBuyGoods10
              ? 'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/38yuanlijishiyong.png'
              : 'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/99yuanlingqu3tiantiyan.png'
          }
          onClick={() => {
            onCancel()
            openPayDialog({
              type: 1,
              onClose: (userInfo: any) => {
                if (userInfo?.memberStatus === MemberType.已开通) {
                  cb ? cb() : history.push({
                    pathname: pathName,
                  })
                }
              },
            })
          }}
        />
        {!userInfo?.specialInfo?.isBuyGoods10 && <div className={styles.tip}>到期按照￥38/月自动续费  可随时取消</div>}
      </div>
    </div>
  )
}

const openIntercept = ({ pathName, cb }: any) => {
  const dom = document.getElementById('payInterceptDialog')
  dom && dom.parentElement && dom.parentElement.removeChild(dom)
  const container = document.createElement('div')
  // const id = new Date().getTime() + 'exportDialog';
  const id = 'payInterceptDialog'
  container.setAttribute('id', id)
  container.classList.add(styles.payWrapper)
  document.body.appendChild(container)

  const root = ReactDOM.createRoot(container)
  const cancel = () => {
    root.unmount()
    container && container.parentElement && container.parentElement.removeChild(container)
  }
  window.addEventListener('popstate', function (event) {
    cancel()
  })

  return root.render(
    <Intercept
      getContainer={() => container} // 注意这里我们改为返回container的函数，因为getContainer通常是一个函数
      pathName={pathName}
      cb={cb}
      onCancel={() => {
        cancel()
        //   onClose && onClose(userInfo)
      }}
    />,
  )
}

export default openIntercept
