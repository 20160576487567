import { Button, Modal, Result, Spin, Statistic } from 'antd'
import { ModalProps } from 'antd/es/modal'
import React, { ReactNode, useEffect, useState } from 'react'
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client'
import closeSvg from '@/assets/icon/close.svg'
import moment from 'moment'
import LeftPart from './components/leftPart/index'
import PayContent from './components/payContent/index'
import styles from './index.less'
import { getGoodList, getUserInfo } from './api'
import { desensitizationNumber } from '../../utils/util'
import RprmUtils from '@/utils/dot'
import { setUserInfo, useStore } from '@/states/store'

const { Countdown } = Statistic

interface IPayModalProps {
  // detailSource: number;
  type?: number //tab类型 1套餐 2加油包
  onSuccess?: Function // 支付成功回调
  pageInfo?: string //访问路径
}

const bg1 = 'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/dengluyebeijingtu.png'
const bg2 = 'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/bg_guangxiaobeijing@2x.png'

const PayDialog = ({ onCancel, type = 1, pageInfo = undefined, ...rest }: IPayModalProps & ModalProps) => {
  const [goodsList, setGoodsList] = useState<any>([])
  const [currentTab, setCurrentTab] = useState(type)
  const [payUserInfo, setPayUserInfo] = useState<any>({})
  const [payStatus, setPayStatus] = useState<any>({
    member: 0,
    bag: 0,
  }) //0-未支付，1-已支付，2-支付失败 3-已失效  const bg1 = 'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/bg_huangguanbeijing.png'
  const bg2 = 'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/bg_guangxiaobeijing@2x.png'
  const [curDeadLineTime, setCurDeadLineTime] = useState(new Date().setHours(23, 59, 59, 999))

  useEffect(() => {
    getUser()
  }, [])
  useEffect(() => {
    getGoodsList()
  }, [currentTab])

  useEffect(() => {
    if (currentTab === 1) {
      RprmUtils.rec(`show_pay_tab`, 'view', { en: 'tap' })
    } else {
      RprmUtils.rec(`show_recharge_pay_tab`, 'view', { en: 'tap' })
    }
  }, [currentTab])

  const getUser = async (cb?: any) => {
    // const userId = getCookie('userId');
    getUserInfo({})
      .then((res: any) => {
        const { data } = res || {}
        setPayUserInfo({ ...data })
        cb && cb({ ...data })
      })
      .catch(err => {})
  }
  const getGoodsList = () => {
    getGoodList({ goodsType: currentTab, pageInfo: pageInfo })
      .then((res: any) => {
        const { data } = res || {}
        setGoodsList(data)
      })
      .catch(err => {})
  }

  const closeDialog = (e: any) => {
    if (payStatus['member'] === 1 || payStatus['bag'] === 1) {
      setUserInfo(payUserInfo)
      if (location.href.indexOf('/personal-center') !== -1) {
        location.reload()
      }
    }
    onCancel && onCancel(payUserInfo)
  }

  useEffect(() => {
    RprmUtils.rec(`show_pay_dialog`, 'view', { en: 'tap', et: 'custom' })
  }, [])

  return (
    <Modal open className={styles.payModal} width={960} footer={null} closable={false} {...rest} wrapClassName={styles.maskWrapper}>
      <div className={styles.close} onClick={closeDialog}>
        <img src={closeSvg} />
      </div>
      <div className={styles.payContainer}>
        <div className={styles.leftcontainer}>
          {/* <img
            className={styles.logo}
            src="https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/wimelogofanbai.png"
          /> */}
            <div className={styles.header}>
            <img src={payUserInfo?.avatarUrl} alt="" />
            <div>
              <p className={styles.name}>{payUserInfo?.nickname || payUserInfo?.userId}</p>
              {payUserInfo?.mobileNo && <p className={styles.phone}>{`已绑定手机号：${desensitizationNumber(payUserInfo?.mobileNo)}`}</p>}
            </div>
          </div>
          {/* <LeftPart currentTab={currentTab} /> */}
         
          <img className={styles.leftImg} src= {currentTab===1?'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/huiyuantaocan.png':'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/jiayoubao.png'}alt="" />
        </div>
        <div className={styles.rightContainer}>

          <div className={styles.payContent}>
            <div className={`${currentTab === 1 ? styles.payTab : styles.rechargePayTab}`}>
              <div
                className={styles.memberTab}
                onClick={() => {
                  setCurrentTab(1)
                }}
              >
                <div className={styles.memberTitle}>
                  会员套餐
                  {currentTab === 2 && <div className={styles.tip}>大促特惠</div>}
                </div>
              </div>
              <div
                className={styles.rechargeTab}
                onClick={() => {
                  setCurrentTab(2)
                }}
              >
                <div className={styles.memberTitle}>
                  加油包
                  {currentTab === 2 && (
                    <div className={styles.rechargeCutDown}>
                      <Countdown
                        title={null}
                        value={curDeadLineTime}
                        format="HH:mm:ss:SSS"
                        onFinish={() => {
                          console.log('onFinishonFinishonFinish', new Date().getTime())
                          setTimeout(() => {
                            setCurDeadLineTime(new Date().setHours(23, 59, 59, 999))
                          }, 100)
                          // setCutdownShow(false)
                        }}
                      />
                      后结束
                    </div>
                  )}
                  {currentTab === 1 && <div className={styles.tip}>限时加赠</div>}
                </div>
              </div>
            </div>
            <PayContent
              currentTab={currentTab}
              pageInfo={pageInfo}
              goodsList={goodsList}
              memberStatus={payUserInfo?.memberStatus}
              setTab={(tab: any) => setCurrentTab(tab)}
              payStatus={payStatus}
              setPayStatus={setPayStatus}
              updateUserInfo={(cb: any) => {
                getUser(cb)
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

const openPayDialog = (
  params: {
    onClose?: Function // 取消回调
    pageInfo?: string //访问路径
    type?: number //tab类型 1套餐 2加油包
  } & ModalProps,
) => {
  const { onClose, ...rest } = params
  const dom = document.getElementById('payDialog')
  dom && dom.parentElement && dom.parentElement.removeChild(dom)
  const container = document.createElement('div')
  // const id = new Date().getTime() + 'exportDialog';
  const id = 'payDialog'
  container.setAttribute('id', id)
  container.classList.add(styles.payWrapper)
  document.body.appendChild(container)

  const root = ReactDOM.createRoot(container)
  const cancel = () => {
    root.unmount()
    container && container.parentElement && container.parentElement.removeChild(container)
  }
  window.addEventListener('popstate', function (event) {
    cancel()
  })

  return root.render(
    <PayDialog
      getContainer={() => container} // 注意这里我们改为返回container的函数，因为getContainer通常是一个函数
      // modalVisible={open}
      onCancel={userInfo => {
        cancel()
        onClose && onClose(userInfo)
      }}
      {...rest}
    />,
  )
}

// export default PayDialog;
export default openPayDialog
